/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
    Link,
    withRouter
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../assets/scss/Header.scss';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Navigator from './Navigator';
import MobileNavigator from './MobileNavigator';
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken';
import SyncBalance from './SyncBalance';

import iconTopAmount from '../assets/img/icon_top_amount.png';

const menu = [
    {
        name: '이용방법',
        link: '#navigator',
        subItems: [
            { name: '입출금 신청방법', link: '/Guide/Wallet' },
            { name: '거래방법', link: '/Guide/Trade' }
        ]
    },
    {
        name: '거래하기',
        link: '#navigator',
        subItems: [
            { name: '나스닥 거래', link: '/Exchange/NDX' },
            { name: '코스피 거래', link: '/Exchange/KOSPI200' },
            { name: '비트코인 거래', link: '/Exchange/BTCUSDT' },
            { name: '이더리움 거래', link: '/Exchange/ETHUSDT' },
            { name: '금 거래', link: '/Exchange/XAUUSD' },
            // { name: 'FX 거래', link: '/Exchange/GBPAUD' },
        ]
    },
    {
        name: '입출금신청',
        link: '#navigator',
        subItems: [
            { name: '입금신청', link: '/Wallet/Deposit' },
            { name: '출금신청', link: '/Wallet/Withdraw' },
            { name: '입출금내역', link: '/Wallet/History' }
        ]
    },
    {
        name: '공지사항',
        link: '/Board/Notice'
    },
    {
        name: '고객센터',
        link: '#navigator',
        subItems: [
            { name: '수수료안내', link: '/Info/Fee' },
            { name: '개인정보취급방침', link: '/Info/Policy' },
            { name: '이용약관', link: '/Info/Terms' }
        ]
    },
    {
        name: '마이페이지',
        link: '#navigator',
        subItems: [
            { name: '회원정보', link: '/MyPage/Info' },
            { name: '나의거래내역', link: '/MyPage/ExchangeHistory' },
            { name: '1:1문의', link: '/MyPage/Inquiry' },
            { name: '쪽지함', link: '/MyPage/Message' },
            // { name: '로그인내역', link: '/MyPage/LoginHistory' }
        ]
    }
];

let mobMenu = [...menu];
mobMenu.push({
    name: '로그아웃',
    link: '#logout'
});

class Header extends React.Component {
    logout() {
        localStorage.clear();
        window.location.href = '/';
    }

    constructor(props) {
        super(props);
        this.state = { show: false, setShow: false };
        if (localStorage.token) {
            // jwt.verify(localStorage.token, localStorage.pub, { algorithms: 'ES256' }, (err, decoded) => {
            //     if (err && err.name === 'TokenExpiredError') {
            //         localStorage.clear();
            //         return;
            //     }

            //     SyncBalance.getBalance(this.props);

            //     this.props.decodeToken(decoded);

            //     /* 로그인 되어 있으면 로드 될 때 마다  정보를 가져옴 */
            // });

            // 동기화 된 함수로 변경
            try {
                var decoded = jwt.verify(localStorage.token, localStorage.pub, { algorithms: 'ES256' });
                this.props.decodeToken(decoded);
                this.getBalanceInterval = setInterval(() => {
                    SyncBalance.getBalance(this.props);
                }, 10000);
                SyncBalance.getBalance(this.props);
            } catch (err) {
                // //nolog//console.log('token expired');
                localStorage.clear();
            }
        }
    }

    componentDidMount() {
        this.loginCheck = setInterval(() => {
            try {
                if (localStorage.token) {
                    jwt.verify(localStorage.token, localStorage.pub, { algorithms: 'ES256' });
                }
            } catch (err) {
                localStorage.clear();
                window.location.href = '/';
            }
        }, 60 * 1000);
    }

    componentDidUpdate() {
        // 안읽은 쪽지 체크
        if (localStorage.token) {
            fetch('/api/message', {
                method: "GET",
                headers: {
                    "Authorization": 'Bearer ' + localStorage.token
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res.status === 'OK') {
                        for (let i = 0; i < res.data.length; i++) {
                            if (res.data[i].viewCount === 0) {
                                if (window.location.href.indexOf('/MyPage/Message') === -1) {
                                    window.location.href = '/MyPage/Message';
                                }
                                return;
                            }
                        }
                    } else {
                    }
                });
        }
    }

    componentWillUnmount() {
        if (this.loginCheck) {
            clearInterval(this.loginCheck);
        }
        if (this.getBalanceInterval) {
            clearInterval(this.getBalanceInterval);
        }
    }

    render() {
        return (
            <header>
                <div id="logo">
                    <Link to="/"><img src={this.props.logo} alt={this.props.appName} /></Link>
                </div>
                <Navigator menu={menu} className='navigator' linkClicked={() => { }} />
                <ul className="member_section">
                    <li>
                        {
                            this.props.user && this.props.user.cash !== undefined ?
                                // <SyncBalance.Component />
                                <div className='top-cash'><img src={iconTopAmount} />{this.props.user.cash.toLocaleString()}원</div>
                                :
                                <Link to='/Login'>로그인</Link>
                        }
                    </li>
                    <li>
                        {
                            this.props.user !== undefined ?
                                <div><span className='logout-btn' onClick={this.logout}>로그아웃</span></div>
                                :
                                <Link to='/Register'>회원가입</Link>
                        }
                    </li>
                </ul>
                <ul className="mobile_buttons">
                    {
                        this.props.user && this.props.user.cash !== undefined ?
                            <li>
                                <span className='mobile-top-cash'>{this.props.user.cash.toLocaleString()}원</span>
                            </li>
                            :
                            ''
                    }
                    <li>
                        <span><FontAwesomeIcon icon={faUser} color='white' onClick={() => { window.location.href = '/MyPage/Info'; }} /></span>
                    </li>
                    <li>
                        <MobileNavigator menu={mobMenu} />
                    </li>
                </ul>
            </header>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = {
    decodeToken: (user) => ({ type: 'DECODETOKEN', user: user }),
    getBalance: (balance) => ({ type: 'GETBALANCE', balance: balance })
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))


// export default Header;